import { Grid } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'
import React, { useState, useEffect } from 'react'
import EstadisticasApi from '../../api/estadisticas'
import { useAppContext } from '../../AppContext'
import { format_to_number } from '../../business/Validations'
import CustomSection from '../../components/CustomSection'
import TableComponent, { createColumns } from '../../components/TableComponent'

type Props = {}
const columns : GridColDef[] =[
    createColumns({ headerName : "Asesor", field : "asesor"}),
    createColumns({ headerName : `${new Date().getFullYear()-1}`, field : "anio_1"}),
    createColumns({ headerName : `${new Date().getFullYear()-2}`, field : "anio_2"}),
    createColumns({ headerName : `${new Date().getFullYear()-3}`, field : "anio_3"}),
    createColumns({ headerName : `${new Date().getFullYear()-4}`, field : "anio_4"}),
    createColumns({ headerName : `${new Date().getFullYear()-5}`, field : "anio_5"}),

]

const ComparativoAnualAsesores = (props : Props) => {
    const [ datos, setDatos ] = useState([])
    const { handleActivePage } = useAppContext()
    useEffect(()=>{
        handleActivePage('Comparativo Anual - Asesores')
        obtener_datos()
    },[])

    const obtener_datos = async () =>{
        try {
            const { data } = await EstadisticasApi.getComparativoAsesorAnual()
            setDatos(data.comparativo.map((item : any) => {
                item.anio_1 = format_to_number(item.anio_1);
                item.anio_2 = format_to_number(item.anio_2);
                item.anio_3 = format_to_number(item.anio_3);
                item.anio_4 = format_to_number(item.anio_4);
                item.anio_5 = format_to_number(item.anio_5);
                return item
            }))
        } catch (error) {
            
        }
    }
    const rowSelected = () =>{

    }
    return (
        <CustomSection>
            <Grid container>
                <Grid item xs={12}>
                    <div style={{ height: '100%', width: '100%' }}>
                        <TableComponent rows={datos} columns={columns} rowCallback={ rowSelected } />
                    </div>
                </Grid>
            </Grid>
        </CustomSection>
    )
}

export default ComparativoAnualAsesores